import burblePopup from '../../assets/images/burble-popup.svg';
// import iconLogo from '../../assets/images/icon-logo.svg'

interface PopupProps {
    children: any;
    useChromeIcon?: boolean;
    useClouds?: boolean;
    customDesign?: string;
}

export const CustomPopup = ({ children, useChromeIcon = false, useClouds = false, customDesign } : PopupProps) => {
  return (
    <div className={`fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-[#06010F]/50 z-[300] ${useClouds ? 'popup-container' : ''}`}>
        <div className={`relative popup flex flex-col justify-center items-center h-[calc(100%-40px)] aspect-square bg-center bg-no-repeat bg-cover max-[480px]:w-full max-[480px]:h-[auto] max-[480px]:px-0 ${useChromeIcon ? 'pb-[45px]' : ''}`} style={{ backgroundImage: `url(${burblePopup})` }}>
            <div className={`flex flex-col justify-center items-center popup-content ${customDesign ?? ''}`}>
                {children}
            </div>
        </div>
    </div>
  )
}
