import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

// Services
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from '../../actions/type'
import { authLogin } from '../../services/apis/auth'

// Methods
import { getWorkspaceParams } from '../../common/methods/params'

// Components
import ButtonRedirect from '../../components/shared/ButtonRedirect'
import ButtonReturn from '../../components/shared/ButtonReturn'
import ButtonStar from '../../components/shared/ButtonStar'
import ErrorMessage from '../../components/shared/ErrorMessage'

// Constants
import { MESSAGES } from '../../constants/response_message'

// Models
import { MessageResult } from '../../models/MessageResult'

// Images
import iconEmail from '../../assets/images/icons/icon-email.svg'
import iconLock from '../../assets/images/icons/icon-lock.svg'
import { CustomPopup } from '../../components/popup/customPopup'
import { cleanDataStorage } from '../../helpers/auth'

const Login = () => {
  const { url, workspace, group } = getWorkspaceParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  cleanDataStorage()
  dispatch({ type: LOGOUT })

  const [message, setMessage] = useState(new MessageResult(false, false, ''))
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showChangePassword, setShowChangePassword] = useState<boolean>(false)

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY),
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY)
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      email: '',
      password: '',
      domain: workspace,
      group
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      await authLogin(values).then(data => {
        if (data.status) {
          dispatch({ type: LOGIN_SUCCESS })
          navigate('/' + url + data.page)
        } else {
          dispatch({ type: LOGIN_FAIL })
          setMessage({ isView: true, status: false, message: data.message })
        }
      })
      setIsLoading(false)
    },
    validate: values => {
      validateSchema.validate(values).catch(err => {
        const error: string = err.errors[0]
        setMessage({ isView: true, status: false, message: error })
      })
    }
  })

  return (
    <article className="bg-main auth-page bg-auth bg-loading-form">
      <section className="grid grid-rows-1 w-screen h-screen px-8 max-[480px]:px-0">
        <ButtonReturn
          action={ showChangePassword ? 'callback' : 'return'}
          callback={ showChangePassword ? () => setShowChangePassword(false) : undefined }
          linkReturn={ showChangePassword ? undefined : `/${url}`}
        />

        <div className="grid relative w-[1071px] max-[480px]:w-full items-center justify-self-center after:w-[361px] xl:after:w-[534px] fhd:after:w-[642px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent bg-login">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex flex-col h-full gap-5 place-content-center">
                <div className="grid gap-1">
                  <label className="ff-cp--bold text-[16px] fhd:text-[24px] max-[480px]:text-[14px] text-white" htmlFor="email">Correo</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[17px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="pl-[45px]"
                      onChange={formik.handleChange}
                      onPaste={formik.handleChange}
                      value={formik.values.email}
                      placeholder="abc@gmail.com"
                    />
                  </div>
                </div>

                <div className="grid gap-1">
                  <label className="ff-cp--bold text-[16px] fhd:text-[24px] max-[480px]:text-[14px] text-white" htmlFor="password">Contraseña</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[24px] h-[12px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="pl-[45px]"
                      onChange={formik.handleChange}
                      onPaste={e => e.preventDefault()}
                      value={formik.values.password}
                      placeholder="********"
                    />
                  </div>
                </div>

                <div className="checkbox text-center">
                  <button type='button' onClick={(e) => { setShowChangePassword(true); e.preventDefault() }} className="text-sm fhd:text-[16px] text-white underline underline-offset-4 opacity-70 hover:opacity-100 hover:scale-110">¿Olvidaste la contraseña?</button>
                </div>

                {message.isView && !message.status && (
                  <div className="h-[50px] mb-4 mt-4">
                    <ErrorMessage title={message.message}></ErrorMessage>
                  </div>
                )}
              </div>

              <div className="flex justify-center">
                <ButtonRedirect type="submit" text="Ingresar" borderColor='#A41AE8' showDesing next loading={isLoading}></ButtonRedirect>
              </div>
            </div>
          </form>
        </div>

        {
          showChangePassword &&
          <CustomPopup useClouds>
               <h3 className="ff-cp--bold text-center text-white text-[30px] font-bold leading-7 mb-4 max-[480px]:text-[16px] max-[480px]:mb-2.5">¿Olvidaste tu contraseña?</h3>
              <p className="ff-cp--light max-w-[490px] text-center text-white text-[20px] font-normal mb-[20px] max-[480px]:text-[12px] max-[480px]:w-[75%] max-[480px]:mb-[15px]">¡Estas cosas pasan! Presiona el siguiente botón para crear una nueva contraseña y seguir tu aventura vocacional.</p>
              <ButtonStar
                text='Nueva contraseña'
                type='purple'
                textSize='max-[480px]:text-[10px]'
                callback={() => navigate(`/${url}/recovery/reset-password`)}
              />
              <div className="max-w-[210px] text-center">
                <a href="#" className="text-center text-white text-[14px] font-bold leading-none max-[480px]:text-[11px] max-[480px]:leading-[14px] max-[480px]:block">O presiona aquí para comunicarte con el equipo.</a>
              </div>
          </CustomPopup>
        }
      </section>
    </article>
  )
}

export default Login
