import { useEffect, useState } from 'react'
import CustomScroll from 'react-custom-scroll'

// Services
import { updateProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Methods
import { getRecord } from '../../../common/methods/storage'

// Components
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { REACTIONS } from '../../../constants/reactions'

// Images
import InterestButtonSelected from '../../../assets/images/cards/bg-interest-selected.svg'
import InterestButton from '../../../assets/images/cards/bg-interest.svg'

const Interests = () => {
  const record = getRecord()

  const [isloading, setLoading] = useState<boolean>(true)

  const [features, setFeatures] = useState<any[]>([])
  const [selectedList, setSelectedList] = useState<any[]>([])

  useEffect(() => {
    getFeatures()
  }, [])

  useEffect(() => {
    if (features.length > 0) {
      setTimeout(() => setLoading(false), 4000)
    }
  }, [features])

  const onSetSelectedList = (data: any) => {
    const selected_list = selectedList

    const index = selected_list.findIndex(sl => sl.id === data.id)
    if (index === -1) {
      if (selectedList.length < 6) { selected_list.push({ ...data, feature: data, selected: true, weight: null, reaction: data.profiles.reaction, feature_id: data.id }) }
    } else selected_list.splice(index, 1)

    setSelectedList([...selected_list])
  }

  const getFeatures = () => {
    if (record) {
      const features = record.features.filter((fet: any) => fet.type === 'nouns' && fet.parent_id !== null && fet.profiles.reaction === REACTIONS.LOVE)
      setFeatures(features ?? [])
    }
  }

  const isContainKey = (data: any) => selectedList.findIndex(sl => sl.id === data.id) !== -1

  const onGoToNextStep = async () => {
    await updateProfilesByList(selectedList)
      .then(async (data) => {
        if (data.status) await updateRecord({ step: 11 })
      })
      .catch(err => console.log(err))
  }

  return (

    <article className="bg-main" id="home">
      <div className="w-screen h-screen overflow-hidden quiz-screen-background relative before:absolute before:bg-gradient-to-b before:from-transparent before:to-[#070625] before:bottom-0 before:right-0 before:left-0 before:w-full before:pointer-events-none before:h-[421px] before:z-10">
        <div className="h-[67px]">
          <ButtonRedirect action="return" linkReturn={PAGES.CONVERSATION_3} text="return"></ButtonRedirect>
          {(selectedList.length === 6 || selectedList.length === features.length) && <ButtonRedirect callback={onGoToNextStep} action="next" linkReturn="/needs/motivation" text="next"></ButtonRedirect>}
          <BadgeStep currentStep={10}></BadgeStep>
        </div>
        <div className="h-[calc(100%-67px)] grid grid-rows-[110px_auto] grid-cols-1 content-background px-[82px] relative z-20">
          <div className="row-[1_/_1] col-span-1">
            <TitleMessage
              index={0}
              size="w-1/2 max-w-[800px] h-auto"
              title={'¿En cuál de estas eres mejor?'}
              message={'Puedes seleccionar más de una opción pero piensa bien<br />qué es lo que REALMENTE quieres  >.<'}
            />
          </div>
          {
            !isloading
              ? <div className="min-h-full row-[2_/_2] col-span-1">
                <div className="grid w-full h-full max-w-[1024px] justify-self-center mx-auto px-6 animation-charge">
                  <div className="grid w-full place-content-center my-4">
                    <div className="flex gap-2">
                      <div className="text-white text-[16px] ff-cp--bold">
                        {selectedList.length}/{features.length < 6 ? features.length : '6'}
                      </div>
                      <div className="w-[200px] h-[5px] bg-[#ffffff4b] self-center rounded-md overflow-hidden">
                        <div className="h-[5px] bg-white rounded-md ease-in" style={{ width: 100 * selectedList.length / 6 + '%', transition: 'width 500ms' }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="tmc-scroll pt-[10px] min-h-full">
                    <CustomScroll allowOuterScroll={true} className="h-full">
                      <div className="w-[100%] h-full gap-2 grid row-start-2 row-span-5" style={{ gridTemplateColumns: 'repeat(auto-fit, 220px)' }}>
                        {features.map((feature: any) => {
                          return (
                            <div className={`w-[220px] h-[155px] text--webkit col-span-1 cursor-pointer z-40 transition ease-in hover:scale-110 ${isContainKey(feature) ? 'opacity-100' : 'opacity-70 hover:opacity-100'}`} key={feature.id} onClick={() => onSetSelectedList(feature)}>
                              <div className="w-[220px] h-[155px] bg-cover bg-no-repeat bg-center grid grid-cols-6 place-content-center row-span-5"
                                style={{ backgroundImage: `url(${isContainKey(feature) ? InterestButtonSelected : InterestButton})` }}>
                                <div className="grid col-start-2 col-end-6 ff-cp--bold text-[21px] leading-[23px] text-white">
                                  {feature.name}
                                </div>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </CustomScroll>
                  </div>
                </div>
              </div>
              : ''}
        </div>
      </div>
    </article>
  )
}

export default Interests
