import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LOGOUT } from '../../actions/type'
import { isMobile } from '../../common/methods/devices'
import { getWorkspaceParams } from '../../common/methods/params'
import { cleanDataStorage } from '../../helpers/auth'

import ButtonRedirect from '../../components/shared/ButtonRedirect'

import chromeAlert from '../../assets/images/logos/chrome_alert.svg'
import moveMatchLogo from '../../assets/images/movematch.svg'

function Home () {
  const { url } = getWorkspaceParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  cleanDataStorage()
  dispatch({ type: LOGOUT })

  return (
    <section id="home">
      <div className="w-screen h-screen flex items-center justify-center overflow-hidden bg-cover bg-auth">
        <div className="w-full h-full flex items-center justify-center after:w-[554px] after:h-[700px] after:absolute after:bottom-0 after:left-0 after:right-0 after:mx-auto after:opacity-10 after:bg-gradient-to-t after:from-violet-600 after:to-transparent after:max-w-[100%] z-10">
          <div className="relative bg-intro h-full aspect-[1.34] inset-0 m-auto flex items-end justify-center home-loop-before">
            <div className="w-full h-[67%] gap-1 max-[480px]:h-[100%] max-[480px]:justify-evenly flex flex-col items-center justify-between z-50 min-[481px]:pt-4 max-[480px]:pt-14 fhd:pt-[100px] min-[481px]:mb-[10%]">
              <h2 className='ff-cp--bold text-white text-[18px] max-[480px]:text-[8px] xl:text-[20px] flex flex-col justify-center items-center justify-self-center fhd:text-[54px] home-loop'>
                <img src={moveMatchLogo} alt='movematch' className='max-[480px]:w-[calc(100dvw*.35)] max-[480px]:h-auto' />
                Construye tu destino
              </h2>
              <div className='relative h-[102px] max-[480px]:h-[50px] xl:h-[150px] fhd:h-[190px] flex flex-col gap-y-4 items-center fadeUp'>
                <ButtonRedirect callback={() => navigate(`/${url}/register`)} type='button' text='Regístrate' borderColor='#A41AE8' showDesing></ButtonRedirect>
                <ButtonRedirect callback={() => navigate(`/${url}/login`)} type='button' text='Ingresar' borderColor='#A41AE8'></ButtonRedirect>
              </div>
            </div>
          </div>
        </div>
        {
          !isMobile() &&
          <div className="fixed right-4 bottom-4">
            <img src={chromeAlert} alt="chrome" className="w-[250px] h-[80px]" />
          </div>
        }
      </div>
    </section>
  )
}

export default Home
