import { useMemo } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import bgViejaToxic from '../../assets/images/bubble-vieja-destino-warning.svg'
import bgVieja from '../../assets/images/bubble-vieja-destino.svg'
import btnViewMore from '../../assets/images/buttons/btn-purple.svg'
import iconTooltipRight from '../../assets/images/icons/icon-joyride-right.svg'

const IntroTooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  size,
  tooltipProps,
  handleYes,
  handleNo
}: TooltipRenderProps & { handleYes?: () => void; handleNo?: () => void }) => {
  const toxicText = useMemo(() => {
    if (step?.title === 'toxic') {
      const [text1, career, text2, features, text5] = (step?.content as string)?.split(';') ?? []
      return (
          <div className="text-white text-[18px] max-[480px]:text-[11px] max-[480px]:leading-[12px] leading-none tracking-tight text-center">
            <label className="font-light">{text1}</label>
            <label className="font-extrabold">{career} </label>
            <label className="font-light">{text2} </label>
            <label className="font-extrabold underline">{features}</label>
            <label className="font-light">{text5}</label>
        </div>
      )
    } else {
      <></>
    }
  }, [step])

  const renderButtons = () => {
    switch (step?.title) {
      case 'mesh':
        return (
          <button
            {...primaryProps}
            style={{ backgroundImage: `url(${btnViewMore})` }}
            className="flex w-[216px] h-[57px] gap-4 cursor-pointer ff-cg--bold text-white leading-none opacity-80 text-[16px] items-center justify-center hover:opacity-100 bg-center bg-cover bg-no-repeat max-[480px]:max-w-full max-[480px]:w-[150px] max-[480px]:h-[39px] max-[480px]:text-[12px] max-[480px]:pt-[4px]">
            ¡Vamos!
          </button>
        )
      case 'question':
        return (
          <>
          <button
            onClick={handleYes}
            style={{ backgroundImage: `url(${btnViewMore})` }}
            className="flex w-[216px] h-[57px] gap-4 cursor-pointer ff-cg--bold text-white leading-none opacity-80 text-[16px] items-center justify-center hover:opacity-100 bg-center bg-cover bg-no-repeat max-[480px]:w-[150px] max-[480px]:h-[39px] max-[480px]:text-[12px] max-[480px]:pt-[4px]">
            Sí, estoy satisfecho
          </button>
          <button
            onClick={handleNo}
            style={{ backgroundImage: `url(${btnViewMore})` }}
            className="flex w-[216px] h-[57px] gap-4 cursor-pointer ff-cg--bold text-white leading-none opacity-80 text-[16px] items-center justify-center hover:opacity-100 bg-center bg-cover bg-no-repeat max-[480px]:w-[150px] max-[480px]:h-[39px] max-[480px]:text-[12px] max-[480px]:pt-[4px]">
            Quisiera ver otras <br />opciones
          </button>
        </>
        );
      default:
        return (
          <>
        {/* { index > 0 && (
          <button {...backProps} className="flex justify-center items-center w-[52px] h-[52px] rounded-[4px]">
              <span id="back"><img src={iconTooltipLeft} alt="" /></span>
          </button>
        )} */}
        { step?.title !== 'disable_next' && (
          <button {...primaryProps} className="flex justify-center items-center w-[52px] h-[52px] rounded-[4px] transition-all ease-in-out duration-300 hover:scale-110 max-[480px]:w-[40px] max-[480px]:h-[40px]">
            {
              !isLastStep
                ? (
                  <span><img src={iconTooltipRight} alt="" /></span>
                  )
                : (
                  <span><img src={iconTooltipRight} alt="" /></span>
                  )
            }
          </button>
        )}
        </>
        )
    }
  }

  return (
        <div {...tooltipProps}>
            { (step.content !== '') && (
              <>
                <div
                className={`relative bg-cover mt-[280px] pr-[50px] pb-[20px] pl-[185px] flex items-center w-[743px] h-[163px] max-[480px]:w-[calc(100dvw-10px)] max-[480px]:h-auto max-[480px]:bg-contain max-[480px]:bg-no-repeat ${step?.title === 'toxic' ? 'max-[480px]:aspect-[4.5]' : 'max-[480px]:aspect-[4]'} max-[480px]:pl-[calc(100dvw*.265)] max-[480px]:pr-[calc(100dvw*0.055)] max-[480px]:pb-[16px] ${step?.title === 'toxic' ? 'w-[743px] pt-[25px] pl-[170px]' : 'w-[775px] pt-[30px] max-[480px]:pt-[12px]'}`}
                style={{ backgroundImage: `url(${step?.title === 'toxic' ? bgViejaToxic : bgVieja})` }}
            >
                <div className="text-white w-full text-[20px] leading-none tracking-tight max-[480px]:text-[12.5px]">
                    {
                      step?.title === 'toxic'
                        ? <div>
                          { toxicText }
                        </div>
                        : <p className="text-center">{ <span>{step?.content}</span>}</p>
                    }
                </div>
                {/* { (index + 1) === size && (
                  <button {...skipProps} className="absolute top-[0] right-[-20px] w-[55px] h-[55px] z-[10]" style={{ backgroundImage: `url(${iconClose})` }}></button>
                )} */}
            </div>
            <div className="flex justify-center gap-[40px] max-[480px]:gap-[10px] items-end">
                    {/* <button {...skipProps} className="ff-cp-regular text-[10px] h-[28px] uppercase">
                        <span id="skip">Ignorar</span>
                    </button> */}
                    { renderButtons() }
                </div>
              </>
            )}
        </div>
  )
}

export default IntroTooltip
