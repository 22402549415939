import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { LoadingAssets } from '../components/shared/LoadingAssets'

const Layout = ({ children }: { children: any}) => {
  const location = useLocation();
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false)

  const charge_first = window.sessionStorage.getItem('charge_loading_first') ?? 'false'

  useEffect(() => {
    if (location.pathname.split('/').length === 3 && charge_first === 'false') {
      setLoadingAssets(true)
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingAssets(false)
      window.sessionStorage.setItem('charge_loading_first', 'true')
    }, 4000);
  }, [])

  return (
         <>
            {/* <Header /> */}
                {
                  loadingAssets &&
                  <LoadingAssets />
                }
                <main className="main">
                    {
                      !loadingAssets &&
                      children
                    }
                </main>
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                />
            {/* <Footer/> */}
         </>
  )
}

export default Layout
