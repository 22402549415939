import { PAGES } from '../Pages'

// "next": "/question"
export const conversation = [
  {
    id: '1fc14356-9938-4ce8-9124-c2f156d7aa28',
    app_messages: [
      {
        key: 1,
        message: {
          title: 'Hola, soy la Vieja Destino.',
          content: 'Encontrar una carrera es como encontrar una pareja que amas.'
        }
      },
      {
        key: 2,
        message: {
          content: '¿Quieres amar lo que harás en el futuro?'
        }
      }
    ],
    user_message: [
      {
        key: 1,
        message: {
          title: 'No sé, me has dejado pensando'
        }
      },
      {
        key: 2,
        message: {
          title: 'Quisiera intentarlo'
        }
      },
      {
        key: 3,
        message: {
          title: '¡Sí, busco encontrar el amor a mi carrera!'
        }
      }
    ],
    response: {
      key: 3,
      message: {
        content: 'Primero debes conocerte y aprender a amarte.'
      }
    },
    step: 1,
    previous: '/login',
    next: '/decision/7dad568f-8cac-4cea-8dc2-3aaf90ebdd01'
  },
  {
    id: '5f12d57d-a5a7-4779-822f-cc13828c076a',
    app_messages: [
      {
        key: 1,
        message: {
          content: 'Está bien no saber, vayamos a que puedas conocerte un poco más.'
        }
      }
    ],
    user_message: [
      {
        key: 1,
        message: {
          title: 'Estoy abierto a tus sugerencias'
        }
      },
      {
        key: 2,
        message: {
          title: 'Está bien, necesito conocerme más'
        }
      },
      {
        key: 3,
        message: {
          title: 'Sí, quiero saber más sobre mi'
        }
      }
    ],
    response: {
      key: 2,
      message: {
        content: 'Está bien, te acompañaré en este viaje de autoconocimiento.'
      }
    },
    step: 4,
    previous: '/initial-input',
    next: '/professions'
  },
  {
    id: '9c70d5ad-24ce-4ef0-9b5c-9bff6d5fdf9f',
    app_messages: [
      {
        key: 1,
        message: {
          content: '¡Veo que tienes algo claro en mente!'
        }
      }
    ],
    user_message: [
      {
        key: 1,
        message: {
          title: 'Es algo pero no es seguro'
        }
      },
      {
        key: 2,
        message: {
          title: 'No sabía que más poner'
        }
      },
      {
        key: 3,
        message: {
          title: 'Sí, es lo que siempre he querido'
        }
      }
    ],
    response: {
      key: 2,
      message: {
        content: 'Está bien, te acompañaré en este viaje de autoconocimiento.'
      }
    },
    step: 4,
    previous: '/initial-input',
    next: '/professions'
  },
  {
    id: '6e69729e-af31-408b-b7b5-8c8244365212',
    app_messages: [
      {
        key: 1,
        message: {
          content: '¡Veo que tienes más de un camino en mente!'
        }
      }
    ],
    user_message: [
      {
        key: 1,
        message: {
          title: 'Sí, pero no me convence ninguno'
        }
      },
      {
        key: 2,
        message: {
          title: 'Sí, pero eso me genera confusión'
        }
      },
      {
        key: 3,
        message: {
          title: 'Sí, me gustan muchas cosas'
        }
      }
    ],
    response: {
      key: 2,
      message: {
        content: 'Está bien, te acompañaré en este viaje de autoconocimiento.'
      }
    },
    step: 4,
    previous: '/initial-input',
    next: '/professions'
  },
  {
    id: '7985e694-e61e-45a5-a659-3aa757d154d6',
    app_messages: [
      {
        key: 1,
        message: {
          title: 'Waaaaau ¡cuántas cosas te gustan!',
          content: 'En tu destino puedes integrar lo que quieras, <br />oero recuerda que una carrera no puede tenerlo todo.'
        }
      }
    ],
    user_message: [],
    response: {
      key: 2,
      message: {
        content: 'Sigamos conociéndonos para ayudarte en tu decisión...'
      }
    },
    step: 13,
    previous: '/disciplines/748608c0-90a8-11ed-948b-834ce149ae0d',
    next: '/verbs/motivation'
  },
  {
    id: 'dadd773b-1846-4bd2-8f8f-7a19553e093f',
    app_messages: [
      {
        key: 1,
        message: {
          title: '',
          content: 'Ok, continuemos.<br/>Pero los resultados van a ser un poco confusos.'
        }
      }
    ],
    user_message: [],
    response: {
      key: 1,
      message: {
        content: 'Sigamos conociéndonos para ayudarte en tu decisión...'
      }
    },
    step: 26,
    previous: PAGES.DECISION_3,
    next: '/loading/a460712c-9213-11ed-a1eb-0242ac120002'
  }
]
