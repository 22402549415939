import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
import { getWorkspaceParams } from '../../../common/methods/params'
import { deleteCareerReferenceByUserId } from '../../../services/apis/career_reference'
import { getCurrentRecord, updateRecord } from '../../../services/apis/record'

// Components
import AppMessage from '../../../components/shared/AppMessage'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'

// Constants
import { isMobile } from '../../../common/methods/devices'
import { PAGES } from '../../../constants/Pages'

function Question () {
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()

  const [isSelected, setSelected] = useState<any>(null)
  const [isloading, setLoading] = useState<boolean>(true)

  const handleChange = async (value: boolean) => {
    let nextPage = PAGES.CONVERSATION_2
    let nextStep = 4

    if (value) {
      nextPage = PAGES.INITIAL_INPUT
      nextStep = 3
    } else {
      await deleteCareerReferenceByUserId()
    }

    await updateRecord({ step: nextStep })
      .then(() => {
        setSelected(value)
        navigate(`/${url}${nextPage}`)
      })
  }

  useEffect(() => {
    getCurrentRecord()
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (

    <article className="bg-main bg-oldwoman bg-compass loading-conversation" id="home">
        <section className="w-screen h-screen overflow-hidden quiz-screen-background cloud-page py-4 relative">
          <div className="gradient-purple"></div>

          <ButtonRedirect action="return" linkReturn={isMobile() ? PAGES.CONVERSATION_1 : PAGES.DECISION_1} text="return"/>
          <BadgeStep currentStep={2}/>
          <AppMessage
            index={0}
            useAnimationLoading={false}
            size="w-1/2 max-w-[800px] h-auto"
            title="¿Tienes alguna carrera en mente?"
            message="Búscala y selecciona las que desees."
          />

          {
            !isloading &&
            <div className="h-full">
              <div className="user-conversation-group z-[120]">
                {
                  !isloading &&
                  <div className="flex gap-16 px-[75px]">
                      <ButtonStar
                        text='No, prefiero no ver carreras aún'
                        type='white'
                        selected={isSelected === false}
                        callback={() => handleChange(false)}
                        textSize="max-[480px]:text-[10.5px]"
                      />
                      <ButtonStar
                        text='Sí, muéstrame carreras'
                        type='white'
                        selected={isSelected === true}
                        callback={() => handleChange(true)}
                        textSize="max-[480px]:text-[10.5px]"
                      />
                  </div>
                }
              </div>
            </div>
          }
        </section>
    </article>
  )
}

export default Question
