import iconBuceadorNoSelected from '../../assets/images/buceador-stat-noselected.svg'
import iconBuceadorSelected from '../../assets/images/buceador-stat-selected.svg'
import iconConquistadorNoSelected from '../../assets/images/conquistador-stat-noselected.svg'
import iconConquistadorSelected from '../../assets/images/conquistador-stat-selected.svg'
import iconCreadorNoSelected from '../../assets/images/creador-stat-noselected.svg'
import iconCreadorSelected from '../../assets/images/creador-stat-selected.svg'
import iconTejedorNoSelected from '../../assets/images/tejedor-stat-noselected.svg'
import iconTejedorSelected from '../../assets/images/tejedor-stat-selected.svg'

import bgModalBuceador from '../../assets/images/modals/bg-modal-buceador.svg'
import bgModalConquistador from '../../assets/images/modals/bg-modal-conquistador.svg'
import bgModalCreador from '../../assets/images/modals/bg-modal-creador.svg'
import bgModalTejedor from '../../assets/images/modals/bg-modal-tejedor.svg'

import infoBuceador from '../../assets/images/icons/info-buceador.svg'
import infoConquistador from '../../assets/images/icons/info-conquistador.svg'
import infoCreador from '../../assets/images/icons/info-creador.svg'
import infoTejedor from '../../assets/images/icons/info-tejedor.svg'

import closeBuceador from '../../assets/images/icons/close-buceador.svg'
import closeConquistador from '../../assets/images/icons/close-conquistador.svg'
import closeCreador from '../../assets/images/icons/close-creador.svg'
import closeTejedor from '../../assets/images/icons/close-tejedor.svg'

import shieldBuceador from '../../assets/images/shields/shield-buceador.svg'
import shieldConquistador from '../../assets/images/shields/shield-conquistador.svg'
import shieldCreador from '../../assets/images/shields/shield-creador.svg'
import shieldTejedor from '../../assets/images/shields/shield-tejedor.svg'

import brandBuceador from '../../assets/images/brand-buceador.svg'
import brandConquistador from '../../assets/images/brand-conquistador.svg'
import brandCreador from '../../assets/images/brand-creador.svg'
import brandTejedor from '../../assets/images/brand-tejedor.svg'

import btnStarGreen from '../../assets/images/btn-star-green.svg'
import btnStarLightBlue from '../../assets/images/btn-star-light-blue.svg'
import btnStarRed from '../../assets/images/btn-star-red.svg'
import btnStarYellow from '../../assets/images/btn-star-yellow.svg'

import btnGreenReverse from '../../assets/images/button-star-dark-green-reverse.svg'
import btnLightBlueReverse from '../../assets/images/button-star-light-blue-reverse.svg'
import btnRedBrightReverse from '../../assets/images/button-star-red-bright-reverse.svg'
import btnYellowBrightReverse from '../../assets/images/button-star-yellow-bright-reverse.svg'

import avatarConqueror from '../../assets/images/avatars/avatar-conqueror.svg'
import avatarCreator from '../../assets/images/avatars/avatar-creator.svg'
import avatarDiver from '../../assets/images/avatars/avatar-diver.svg'
import avatarWeaver from '../../assets/images/avatars/avatar-weaver.svg'

import profileConqueror from '../../assets/images/constellations/profile_conqueror.svg'
import profileCreator from '../../assets/images/constellations/profile_creator.svg'
import profileDiver from '../../assets/images/constellations/profile_diver.svg'
import profileWeaver from '../../assets/images/constellations/profile_weaver.svg'

import bubbleWeaver from '../../assets/images/bubble/bubble_blue.svg'
import bubbleDiver from '../../assets/images/bubble/bubble_green.svg'
import bubbleConqueror from '../../assets/images/bubble/bubble_red.svg'
import bubbleCreator from '../../assets/images/bubble/bubble_yellow.svg'

import bubbleWeaverMobile from '../../assets/images/bubble/bubble_blue_mobile.svg'
import bubbleDiverMobile from '../../assets/images/bubble/bubble_green_mobile.svg'
import bubbleConquerorMobile from '../../assets/images/bubble/bubble_red_mobile.svg'
import bubbleCreatorMobile from '../../assets/images/bubble/bubble_yellow_mobile.svg'

import cardBubbleWeaver from '../../assets/images/bubble/titles/profile_bubble_title.svg'
import cardBubbleConqueror from '../../assets/images/bubble/titles/profile_conqueror_bubble_title.svg'
import cardBubbleCreator from '../../assets/images/bubble/titles/profile_creator_bubble_title.svg'
import cardBubbleDiver from '../../assets/images/bubble/titles/profile_diver_bubble_title.svg'

import cardTitleBlue from '../../assets/images/cards/card_title_blue.svg'
import cardTitleGreen from '../../assets/images/cards/card_title_green.svg'
import cardTitleRed from '../../assets/images/cards/card_title_red.svg'
import cardTitleYellow from '../../assets/images/cards/card_title_yellow.svg'

export const profile_stat = [
  {
    id: '84740380-91e4-11ed-be92-d7ec6112838f',
    iconSelected: iconBuceadorSelected,
    iconNoSelected: iconBuceadorNoSelected,
    card: btnStarGreen,
    simple: btnGreenReverse,
    modal: bgModalBuceador,
    brand: brandBuceador,
    profile: profileDiver,
    class: 'gradient-green',
    icon: infoBuceador,
    close: closeBuceador,
    iconFree: shieldBuceador,
    avatar: avatarDiver,
    name: 'Buceador',
    side: 'right-[14.5%] max-[480px]:right-[21%]',
    top: 'top-[30%] max-[480px]:top-[38%]',
    animation: 'image3-animation-float',
    dropColor: 'drop-shadow-[0px_0px_85px_#09A474]',
    boxShadow: 'shadow-[0px_350px_105px_105px_#09a47445]',
    description: 'Los buceadores son personas receptivas orientadas a lo metódico y a zambullirse en mares de conocimiento. Se preguntan constantemente sobre la lógica de las cosas para ir en búsqueda de la verdad. - ¿Te identificas conmigo?',
    bubble: bubbleDiver,
    bubble_mobile: bubbleDiverMobile,
    avatarName: 'Verite',
    mainColor: 'text-[#19F1AE]',
    card_title: cardBubbleDiver,
    card_selected: cardTitleGreen,
    fadeIn: '3s'
  },
  {
    id: 'f4819490-91e3-11ed-be92-d7ec6112838f',
    iconSelected: iconCreadorSelected,
    iconNoSelected: iconCreadorNoSelected,
    card: btnStarYellow,
    simple: btnYellowBrightReverse,
    modal: bgModalCreador,
    brand: brandCreador,
    profile: profileCreator,
    class: 'gradient-yellow',
    icon: infoCreador,
    close: closeCreador,
    iconFree: shieldCreador,
    avatar: avatarCreator,
    name: 'Creador',
    side: 'left-[22.5%] max-[480px]:left-[0px]',
    top: 'bottom-0 max-[480px]:bottom-[auto] max-[480px]:top-[48%]',
    animation: 'image2-animation-float',
    dropColor: 'drop-shadow-[0px_0px_95px_#F8E24D]',
    description: 'Los creadores son personas activas orientadas al cambio buscando dejar un legado de novedad en el mundo. Su pregunta para tomar decisiones es: ¿Esto me gusta? - ¿Te identificas conmigo?',
    boxShadow: 'shadow-[0px_350px_105px_105px_#f8e24d2e]',
    bubble: bubbleCreator,
    bubble_mobile: bubbleCreatorMobile,
    avatarName: 'Nova',
    mainColor: 'text-[#F8E24D]',
    card_title: cardBubbleCreator,
    card_selected: cardTitleYellow,
    fadeIn: '1.5s'
  },
  {
    id: '7d30fe80-91e3-11ed-be92-d7ec6112838f',
    iconSelected: iconConquistadorSelected,
    iconNoSelected: iconConquistadorNoSelected,
    card: btnStarRed,
    simple: btnRedBrightReverse,
    modal: bgModalConquistador,
    brand: brandConquistador,
    profile: profileConqueror,
    class: 'gradient-red',
    icon: infoConquistador,
    close: closeConquistador,
    iconFree: shieldConquistador,
    avatar: avatarConqueror,
    name: 'Conquistador',
    side: 'left-[14.5%] max-[480px]:left-[24%]',
    top: 'top-[30%] max-[480px]:top-[38%]',
    animation: 'image1-animation-float',
    dropColor: 'drop-shadow-[0px_0px_85px_#F75748]',
    description: 'Los conquistadores son personas activas y orientadas a buscar resultados objetivos con los cuales puedan dejar un legado de valor. A la hora de tomar decisiones su pregunta decisiva es: ¿Esto me conviene? - ¿Te identificas conmigo?',
    boxShadow: 'shadow-[0px_350px_105px_105px_#f7574838]',
    bubble: bubbleConqueror,
    bubble_mobile: bubbleConquerorMobile,
    avatarName: 'Maxima',
    mainColor: 'text-[#F75748]',
    card_title: cardBubbleConqueror,
    card_selected: cardTitleRed,
    fadeIn: '1s'
  },
  {
    id: '9c58a6e0-91e4-11ed-be92-d7ec6112838f',
    iconSelected: iconTejedorSelected,
    iconNoSelected: iconTejedorNoSelected,
    card: btnStarLightBlue,
    simple: btnLightBlueReverse,
    modal: bgModalTejedor,
    brand: brandTejedor,
    profile: profileWeaver,
    class: 'gradient-blue',
    icon: infoTejedor,
    close: closeTejedor,
    iconFree: shieldTejedor,
    avatar: avatarWeaver,
    name: 'Tejedor',
    side: 'right-[26.8%] max-[480px]:right-[0px]',
    top: 'bottom-[0] max-[480px]:bottom-[auto] max-[480px]:top-[51%]',
    animation: 'image4-animation-float',
    dropColor: 'drop-shadow-[0px_0px_85px_#0D99FF]',
    description: 'Los tejedores son personas receptivas e interesadas en desarrollar sus vínculos con otros seres. El legado que quieren dejar es de un mundo de bienestar, unión y armonía entre todos. - ¿Te identificas conmigo?',
    boxShadow: 'shadow-[0px_350px_105px_105px_#0d99ff4a]',
    bubble: bubbleWeaver,
    bubble_mobile: bubbleWeaverMobile,
    avatarName: 'Alma',
    mainColor: 'text-[#0D99FF]',
    card_title: cardBubbleWeaver,
    card_selected: cardTitleBlue,
    fadeIn: '2.1s'
  }
]
