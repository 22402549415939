export const needs = [
  'motivation',
  'need',
  'values',
  'internal_tools',
  'external_tools',
  'profile_verbs'
]

export const NEED_NAMES = [
  {
    title: 'Motivación',
    code: 'motivation',
    name: 'Motivación',
    pageTitle: 'Selecciona cuál o cuáles verbos<br />representan lo que más te motiva.',
    description: 'Puedes seleccionar más de una opción<br />pero piensa bien qué es lo que REALMENTE quieres.'
  },
  {
    title: 'Necesidad',
    code: 'need',
    name: 'Necesidades',
    pageTitle: 'Selecciona cuál o cuáles verbos<br />representan lo que más necesitas.',
    description: 'Puedes seleccionar más de una opción<br />pero piensa bien qué es lo que REALMENTE quieres.'
  },
  {
    title: 'Valor',
    code: 'values',
    name: 'Valores',
    pageTitle: 'Selecciona qué es lo que más valoras.',
    description: 'Puedes seleccionar más de una opción<br />pero piensa bien qué es lo que REALMENTE quieres.'
  },
  {
    title: 'Herramienta Interna',
    code: 'internal_tools',
    name: 'Herramientas',
    pageTitle: 'Selecciona cuál o cuáles verbos<br />representan lo que sucede en tu mente.',
    description: 'Puedes seleccionar más de una opción pero<br />piensa bien qué es lo que REALMENTE quieres.'
  },
  {
    title: 'Herramienta Externa',
    code: 'external_tools',
    name: '¡Más herramientas!',
    pageTitle: 'Selecciona cuál o cuáles<br />verbos representan cómo actúas',
    description: 'Puedes seleccionar más de una opción pero<br />piensa bien qué es lo que REALMENTE quieres .'
  },
  {
    title: 'Perfiles',
    code: 'profile_verbs',
    name: 'Perfiles',
    pageTitle: 'Selecciona cuál o cuáles<br />verbos representan tu naturaleza',
    description: 'Puedes seleccionar más de una opción pero<br />piensa bien qué es lo que REALMENTE quieres.'
  }
]
