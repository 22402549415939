import { useEffect, useRef, useState } from 'react'

// Services
import { getUser, setUser } from '../../../common/methods/storage'
import { getAllCareers } from '../../../services/apis/career'
import { createReference, createReferencesByList, deleteReference } from '../../../services/apis/career_reference'
import { getAllRecords, updateRecord } from '../../../services/apis/record'
import { setDemoUserStatus } from '../../../services/apis/user'

// Components
import CardInput from '../../../components/cards/CardInput'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Constants
import { PAGES } from '../../../constants/Pages'

// Images
import iconSearch from '../../../assets/images/icon-search.svg'
import { isMobile } from '../../../common/methods/devices'

function InitialInput () {
  const listInnerRef = useRef<any>()
  const user = getUser()

  const [search, setSearch] = useState<string>('')

  const [careers, setCareers] = useState<any[]>([])

  const [selected, setSelected] = useState<any[]>([])

  const [next, setNext] = useState<string>(PAGES.CONVERSATION_2)

  const [prevPage, setPrevPage] = useState<number>(-1)
  const [page, setPage] = useState<number>(0)

  const [allowShine, setAllowShine] = useState<boolean>(false)
  const [allowLimits, setAllowLimits] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [chargeCareers, setChargeCareers] = useState<boolean>(false)

  // const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  const [isFirst, setIsFirst] = useState<boolean>(true)

  useEffect(() => {
    getCheckIsFirstIteration()
    getCareers({})
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (prevPage !== page && chargeCareers) {
      getCareers({})
    }
  }, [chargeCareers])

  useEffect(() => {
    if (user?.demo_input && search.length) {
      setDemoUserStatus(false)
      setUser({ ...user, demo_input: false })
    }
    if (search !== undefined) {
      if (listInnerRef?.current) {
        listInnerRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }

      const abortControllerRef = new AbortController()
      const signal = abortControllerRef.signal

      getCareers({ currentPage: 0, signal })

      return () => abortControllerRef.abort()
    }
  }, [search])

  const getCheckIsFirstIteration = async () => {
    await getAllRecords()
      .then(data => {
        setAllowLimits(data.filter((rds: any) => rds.step >= 25).length === 0)
      })
  }

  const getCareers = async ({ currentPage, signal }: { currentPage?: number | null, signal?: AbortSignal }) => {
    await getAllCareers({ size: 20, page: currentPage ?? page, name: search, signal })
      .then(result => {
        if (result.data) {
          let _careers: any[] = result.data
          const _currentCarrers = (currentPage ?? page) === 0 ? [] : careers.map(career => career.id)

          if (isFirst) {
            const selectedIdList = _careers.filter(career => !!career.referencia).map(career => career.id)
            if (selectedIdList.length === 0) setNext(PAGES.CONVERSATION_2)
            else if (selectedIdList.length === 1) setNext(PAGES.CONVERSATION_4)
            else setNext(PAGES.CONVERSATION_5)

            setSelected([...selectedIdList])
            setIsFirst(false)
          }

          _careers = _careers.filter(career => !_currentCarrers.includes(career.id))

          setPrevPage((currentPage ?? page) - 1)
          setCareers((currentPage ?? page) === 0 ? [..._careers] : [...careers, ..._careers])
        }
      })
      .finally(() => {
        setChargeCareers(false)
      })
  }

  const handleSearch = (event: any) => {
    setSearch(event.target.value)
  }

  const handleToggle = ({ careerId, referenceId } : { careerId: string, referenceId?: string }) => {
    const newSelect = selected
    const index = newSelect.findIndex((sel: any) => sel === careerId)

    if (index === -1) {
      if ((selected.length < 6 && allowLimits) || !allowLimits) {
        newSelect.push(careerId)
      } else {
        setAllowShine(true)
      }
    } else newSelect.splice(index, 1)

    if (newSelect.length === 0) setNext(PAGES.CONVERSATION_2)
    else if (newSelect.length === 1) setNext(PAGES.CONVERSATION_4)
    else setNext(PAGES.CONVERSATION_5)

    const _careers = orderList(newSelect, careerId, referenceId)
    setSelected([...newSelect])
    setCareers([..._careers])
  }

  const orderList = (selectedList: any[], careerId: string, referenceId?: string) => {
    const _careers = careers
    _careers.forEach(career => {
      if (careerId === career.id) {
        career.referencia = referenceId
      }
    })

    let seletedCareers = _careers.filter(career => selectedList.includes(career.id))
    let noSelectedCareers = _careers.filter(career => !selectedList.includes(career.id))

    seletedCareers = seletedCareers.slice().sort((a: any, b: any) => {
      if ((a.HasInstitution ? 1 : 0) > (b.HasInstitution ? 1 : 0)) return 1
      else if (((a.HasInstitution ? 1 : 0) < (b.HasInstitution ? 1 : 0))) return -1

      return (a.name ?? '').localeCompare(b.name ?? '')
    })

    noSelectedCareers = noSelectedCareers.slice().sort((a: any, b: any) => {
      if ((a.HasInstitution ? 1 : 0) > (b.HasInstitution ? 1 : 0)) return -1
      else if (((a.HasInstitution ? 1 : 0) < (b.HasInstitution ? 1 : 0))) return 1

      return (a.name ?? '').localeCompare(b.name ?? '')
    })

    return [...seletedCareers, ...noSelectedCareers]
  }

  const handleFavourite = async ({ careerId, referenceId }: { careerId: string, referenceId?:string }) => {
    if (selected.includes(careerId) && !!referenceId) {
      await deleteReference(referenceId)
        .then(response => {
          if (response.status) {
            handleToggle({ careerId })
          }
        })
    } else {
      await createReference(careerId)
        .then(response => {
          if (response.status) {
            handleToggle({ careerId, referenceId: response.data.id })
          }
        })
    }
  }

  const onGoToNextStep = async () => {
    await createReferencesByList(selected)
      .then(async (data) => {
        if (data.status) await updateRecord({ step: 4 })
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= (scrollHeight - 1) && !chargeCareers) {
        setPage(page + 1)
        setChargeCareers(true)
      }
    }
  }

  useEffect(() => {
    if (allowShine) {
      setTimeout(() => {
        setAllowShine(false)
      }, 3000)
    }
  }, [allowShine])

  return (
        <article className={`bg-main initial-input-page ${user?.demo_input && !search.length ? 'user-demo' : 'user-demo-out'}`} id="home">
            <div className="w-screen h-screen overflow-hidden relative">
                <ButtonRedirect action="return" linkReturn={PAGES.QUESTION} text="return"/>
                {selected.length > 0 && <ButtonRedirect callback={onGoToNextStep} action="next" linkReturn={next} text="next" style='simple'/> }
                <BadgeStep currentStep={3}/>
                <div className="h-full py-4 relative">
                    <TitleMessage
                        index={0}
                        useLoading={false}
                        size="w-1/2 max-w-[800px] h-auto pl-[10%]"
                        title="¿Tienes alguna carrera en mente?"
                        message="Búscala y selecciona las que desees"
                    />
                    {
                        !loading && (
                        <div className="w-full h-[calc(100%-210px)] min-w-[390px] max-w-[70dvw]  max-[480px]:min-w-[auto] max-[480px]:max-w-full max-[480px]:w-full flex flex-col gap-y-6 m-auto p-6 max-[480px]:p-0 max-[480px]:pt-3 max-[480px]:h-[calc(100%-(100dvw*0.2)-57px)]">
                            <div className="w-full max-h-[100px] flex xl:grid xl:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 items-center justify-between justify-items-center animation-charge max-[480px]:p-2">

                                <div className="w-[40%] max-[480px]:w-[calc(100%-40px)] xl:w-[65%] h-[36px] min-w-[200px] xl:min-w-[350px] z-50 flex bg-[#070625] border border-solid border-white rounded-[50px] py-1 px-2 justify-self-start input-demo">
                                    <input type="search" className="w-full h-full text-[15px] text-white bg-transparent pl-[12px] mr-[26px] rounded-[50px] appearance-none" placeholder="Escribe una carrera en mente..." value={search} onChange={handleSearch} />
                                    <i className="w-[26px] aspect-square bg-no-repeat bg-center box-content bg-[length:12px_12px] place-self-center border border-[#F1F1F1] rounded-full bg-[#290945]" style={{ backgroundImage: `url(${iconSearch})` }}></i>
                                </div>

                                <span className={`w-[263px] h-[36px] max-[480px]:w-[42px] max-[480px]:h-[34px] max-[480px]:text-[11px] text-[15px] flex justify-center items-center ff-cp--bold text-white bg-[#070625] rounded-[50px] border border-solid border-white ${!isMobile() ? 'left-arrow' : ''} relative justify-self-end transition-all ease-in duration-500 ${allowShine ? 'shadow-[0px_0px_32px_15px_#ffffff]' : ''}`}>
                                    <span className="mr-1 max-[480px]:mr-0">{ selected.length }/6 </span>
                                    { !isMobile() ? 'Carreras seleccionadas' : '' }
                                </span>
                            </div>
                            <div className='relative h-full flex-1 line-initial-input before:contents max-[480px]:h-[calc(100%-80px)]'>
                              <div className="h-full relative overflow-hidden animation-charge border-t-[1px] border-solid border-white only-gradient-purple-reverse">
                                  <div className="tmc-scroll px-4 max-[480px]:px-[2px] pt-[18px] max-[480px]:pt-[8px] h-full">
                                      <div ref={listInnerRef}
                                            onScroll={onScroll}
                                            className='w-full h-full pr-[56px] max-[480px]:pr-0 max-[480px]:px-[5px] overflow-auto'>
                                          <div className="h-full grid py-[30px] gap-x-[15px] gap-y-[10px] max-[480px]:gap-x-[5px] max-[480px]:py-[10px] max-[480px]:pb-[20px] max-[480px]:h-[auto]" style={{ gridTemplateColumns: isMobile() ? 'repeat(auto-fit, 165px)' : 'repeat(auto-fit, 213px)' }}>
                                              { careers.map((career: any) => (
                                                  <CardInput
                                                    key={career.id}
                                                    career={career}
                                                    type="hidden"
                                                    favourite={career.favourite}
                                                    callback={() => handleFavourite({ careerId: career.id, referenceId: career.referencia })}
                                                    active={selected.includes(career.id) && !!career.referencia}
                                                  />
                                              ))}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                        )}
                </div>
            </div>
        </article>
  )
}

export default InitialInput
