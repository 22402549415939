import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getWorkspaceParams } from '../../common/methods/params'
import { loading } from '../../constants/lists/loading'

const Loading = () => {
  const navigate = useNavigate()

  const { token } = useParams()
  const { url } = getWorkspaceParams()

  const [content, setContent] = useState<any>(null)

  const verifyDeviceType = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  useEffect(() => {
    const data = loading.find(lg => lg.id === token)
    setContent(data)
    if (data?.next) {
      setTimeout(() => navigate(`/${url}` + data.next), 2500)
    }
  }, [token])

  return (
    <section id="loading">
      <div className={`${verifyDeviceType() ? 'px-1' : 'px-4'} py-2 relative h-screen w-screen flex items-center justify-center bg-loading`}>
        <span className="bg-mountain"></span>
        {
          content && (
            <div className={'relative w-full h-[256px] z-50 justify-self-center flex flex-col justify-center items-center text-center'}>
              <div className="absolute inset-0 w-full h-full blur-[3.78] opacity-60"
                style={{ background: 'linear-gradient(90deg, rgba(13, 4, 22, 0.00) 0%, #0D0416 51.04%, rgba(13, 4, 22, 0.00) 100%)' }}></div>
              <div className={`${verifyDeviceType() ? 'w-[90%]' : 'w-[60%]'} h-full flex flex-col items-center justify-center py-5 px-14 z-50`}>
                {
                  content.icon && <img className='loading-content w-[120px] h-[120px]' alt="loadingImage" src={content.icon} />
                }
                { content.title && <h3 className={'loading-content ff-cp--bold text-white max-w-[188px] text-center text-[23px] leading-none mt-4 mb-4'}>{content.title}</h3>}
                { content.content && <p className={`loading-content text-white ff-cp--bold text-center ${verifyDeviceType() ? 'text-[16px] leading-[18px]' : 'text-[32px]'} leading-none`}>{content.content}</p> }
              </div>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default Loading
