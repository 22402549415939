export const LoadingAssets = () => {
  return (
    <section id="loading-assets" className="fixed top-0 bottom-0 right-0 left-0 z-[1000]">
        <div className="w-screen h-screen flex items-center justify-center overflow-hidden bg-loading-assets">
          <h3 className={'ff-cp--bold text-white max-w-[358px] text-center text-[23px] leading-none mt-4 mb-4 max-[480px]:text-[15px] max-[480px]:max-w-[250px]'}>
            Paciencia. Tu futuro está por empezar...
          </h3>
        </div>
    </section>
  )
}
