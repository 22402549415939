import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Services
import { setRecord } from '../../../common/methods/storage'
import { createRecord, updateRecord } from '../../../services/apis/record'

// Componenes
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'
import TitleMessage from '../../../components/shared/TitleMessage'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'

// Constants
import { PURPLE_GRADIENT } from '../../../constants/gradients'
import { PAGES, PAGES_ROUTES } from '../../../constants/Pages'

// Images
import btnProx from '../../../assets/images/btn-beta.svg'
import btnPurpleStar from '../../../assets/images/buttons/btn-purple-star.svg'
import optionGoToQuiz from '../../../assets/images/buttons/option-go-to-quiz.svg'
import iconChrome from '../../../assets/images/icons/icon-chrome.svg'
import optionGoToGame2 from '../../../assets/images/option-to-game-2.png'
import { CustomPopup } from '../../../components/popup/customPopup'

const Decision = () => {
  const navigate = useNavigate()

  const { token } = useParams()

  const { url } = getWorkspaceParams()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentMessage, setCurrentMessage] = useState<any>({})
  const [buttonTextFlow, setButtonTextFlow] = useState<string>('Seguir en MOVE Match')
  const [textGame, setTextGame] = useState<any>({ title: '', content: '' })
  const [textQuiz, setTextQuiz] = useState<any>({ title: '', content: '' })

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const [callback, setCallback] = useState<any>(null)

  const [loadingPage, setLoadingPage] = useState<boolean>(true)

  const goToNextStep = async () => {
    if (!token) return ''

    const CURRENT_PAGE = PAGES_ROUTES.find(({ route }) => route.includes(token))

    if (CURRENT_PAGE) {
      await updateRecord({ step: currentStep + 1, type: 'search' })
        .then(() => navigate(`/${url}${CURRENT_PAGE.next?.SIMPLE ?? ''}`))
    }
  }

  const goToGame = async () => {
    await updateRecord({ type: 'game', step: currentStep })
      .then(() => navigate(`/${url}/game`))
  }

  const goToPreviousStep = () => {
    const CURRENT_PAGE = PAGES_ROUTES.find(({ step }) => step === currentStep)
    return CURRENT_PAGE?.previous ?? `/decision/${token}`
  }

  const remakeQuiz = async (type: string) => {
    await createRecord({ type })
      .then(data => {
        if (data.status) {
          const record = {
            ...data.record,
            features: [],
            profiles: [],
            shield: '',
            demo: true
          }
          setRecord(record)
          navigate(`/${url}${PAGES.CONVERSATION_1}`)
        }
      })
  }

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        setLoadingPage(false)
      }, 1000);
      if (PAGES.DECISION_1.includes(token)) {
        setCurrentStep(2)
        setButtonTextFlow('Seguir en MOVE Match')
        setCallback(null)
        setTextGame({
          title: 'Prueba el juego',
          content: 'Conócete a través de nuestro videojuego'
        })
        setTextQuiz({
          title: 'Continuar en el buscador',
          content: 'Responde a las preguntas'
        })
        setCurrentMessage({
          title: '',
          message: 'Dime, ¿quisieras conocerte más a través de un juego o responder a las preguntas para descubrir el match con tu carrera?'
        })
      } else if (PAGES.DECISION_2.includes(token)) {
        setCurrentStep(25)
        setButtonTextFlow('Continuar')
        setCallback(null)
        setTextGame({
          title: 'Prueba el juego',
          content: 'Conócete a través de nuestro videojuego'
        })
        setTextQuiz({
          title: 'Continúa la experiencia',
          content: 'Continúa en el buscador'
        })
        setCurrentMessage({
          title: '¿Te fue difícil responder a estas preguntas?',
          message: 'Para eso debes conocerte, puedes hacerlo a través de un <br /> juego o continuar respondiendo preguntas.'
        })
      } else if (PAGES.DECISION_3.includes(token)) {
        setCurrentStep(26)
        setButtonTextFlow('Continuar')
        setCallback(null)
        setTextGame({
          title: 'Prueba el juego',
          content: 'Conócete a través de nuestro videojuego'
        })
        setTextQuiz({
          title: 'Continúa la experiencia',
          content: 'Continúa en el buscador'
        })
        setCurrentMessage({
          title: '',
          message: 'Notamos una contradicción en tus respuestas. Te aconsejo que vayas al juego para que puedas conocerte mejor.'
        })
      } else if (PAGES.DECISION_4.includes(token)) {
        setCurrentStep(27)
        setButtonTextFlow('Seguir en MOVE Match')
        setCallback('remake')
        setTextGame({
          title: 'Prueba el juego',
          content: 'Conócete a través de nuestro videojuego'
        })
        setTextQuiz({
          title: 'Revive la experiencia',
          content: 'Continúa en el buscador'
        })
        setCurrentMessage({
          title: '',
          message: 'Parece que todavía necesitas conocerte un poco más, una gran forma es hacerlo mediante el juego.'
        })
      }
    }
  }, [])

  return (
    <article className="bg-main loading-conversation" id="home">
      <section className="w-screen h-screen overflow-hidden relative cloud-page flex flex-col gap-y-4 py-4">
        <ButtonRedirect action="return" linkReturn={goToPreviousStep()} text="return" />
        <BadgeStep type="user" />
        {
          currentMessage?.message &&
          <TitleMessage
            index={0}
            useLoading={false}
            size="w-1/2 max-w-[850px] h-auto pl-[13%]"
            title={currentMessage.title}
            message={currentMessage.message}
          />
        }
        <div className={`w-full flex-1 flex items-center justify-center p-10 bg-loading-decision ${loadingPage ? '' : 'z-[100]'}`}>
          <button
            onClick={() => setShowConfirm(true)}
            className={`w-[293px] h-[262px] xl:w-[448px] xl:h-[393px] z-[80] object-fill bg-auto bg-no-repeat bg-left rounded-[25px_10px_10px_10px] fill-[#371985] transition-all ease-in drop-shadow-[0px_0px_10px_rgba(255,255,255,.5)] opacity-60 hover:z-[90] hover:opacity-100 hover:drop-shadow-[0px_0px_120px_rgba(255,255,255,1)]  border-white border-solid border-[2px]  ${PURPLE_GRADIENT} after:left-[0px] after:right-[0px] after:bottom-[0px!important]`}
            style={{ backgroundImage: `url(${optionGoToGame2})` }}
          >
            <img src={btnProx} className='absolute -left-[35px] top-[15px] w-[450px] h-[100px]' alt="btnProx" />
            <div className="w-full absolute bottom-5 z-50 pl-[15px] flex flex-col gap-y-3 text-white items-center justify-center">
              <div className="flex flex-col">
                <label className="text-center text-[20px] ff-cp--bold">{textGame.title}</label>
                <label className="text-center text-sm ff-cp--regular">{textGame.content}</label>
              </div>
              <div className="w-[194px] h-[54px] bg-no-repeat flex items-center justify-center" style={{ backgroundImage: `url(${btnPurpleStar})` }}>
                <span className="text-md ff-cp--bold">Ir al juego</span>
              </div>
            </div>
          </button>
          <button
            onClick={callback === 'remake' ? () => remakeQuiz('search') : goToNextStep}
            className={`w-[293px] h-[262px] xl:w-[448px] xl:h-[393px] z-[80] object-fill bg-auto bg-no-repeat bg-right rounded-[10px_25px_10px_10px] fill-[#371985] transition-all ease-in opacity-60 drop-shadow-[0px_0px_10px_rgba(255,255,255,.5)] hover:opacity-100 hover:z-[90] hover:drop-shadow-[0px_0px_120px_rgba(255,255,255,1)] ${PURPLE_GRADIENT} after:left-[2px] after:right-[15px]`}
            style={{ backgroundImage: `url(${optionGoToQuiz})` }}
          >
            <div className="w-full absolute bottom-5 z-50 pr-[15px] flex flex-col gap-y-3 text-white items-center justify-center">
              <div className="flex flex-col">
                <label className="text-center text-[20px] ff-cp--bold">{textQuiz.title.split('<br/>').map((txt: string) => {
                  return (
                    <div key={txt}>{txt}</div>
                  )
                })}</label>
                <label className="text-center text-sm ff-cp--regular">{textQuiz.content.split('<br/>').map((txt: string) => {
                  return (
                    <div key={txt}>{txt}</div>
                  )
                })}
                </label>
              </div>
              <div className="w-[194px] h-[54px] bg-no-repeat flex items-center justify-center" style={{ backgroundImage: `url(${btnPurpleStar})` }}>
                <span className="text-md ff-cp--bold">{buttonTextFlow}</span>
              </div>
            </div>
          </button>
        </div>
        <div className="gradient-purple" />
      </section>
      {
        showConfirm &&
        <CustomPopup useChromeIcon>
          <div className='w-[80%] flex flex-col items-center'>
            <img src={iconChrome} alt="chrome" className='mb-[25px]' />
            <h3 className="ff-cp--bold text-center text-white text-[.6vw] md:text-[.8vw] lg:text-[1.3vw] xl:text-[1.8vw] font-bold mb-4">¡Recomendaciones!</h3>
            <p className="ff-cp--light w-full text-center text-white/80 text-[15px] font-normal mb-[20px]">Para disfrutar al máximo del juego, usa Google Chrome, cierra otras pestañas y asegúrate de que tu PC o laptop cumpla con los siguientes requisitos mínimos:</p>
            <p className='ff-m-bold text-white mb-[30px] text-[18px]  flex flex-col items-center'>
              <label>CPU: AMD Ryzen 5 3500U | Intel Core i5</label>
              <label>RAM: 8GB</label>
              <label>SO: Windows 10/11 o macOS</label>
            </p>
            <div className="w-full flex justify-between px-[35px]">
              <ButtonStar text='Déjame revisar' size='h-[8.5vh] aspect-[3.5]'
                textSize='text-[15px]'
                type='purple'
                callback={() => setShowConfirm(false)}></ButtonStar>
              <ButtonStar text='¡Vamos al juego!' size='h-[8.5vh] aspect-[3.5]'
                textSize='text-[15px]'
                type='purple'
                callback={goToGame}></ButtonStar>
            </div>
          </div>
        </CustomPopup>

      }
    </article>
  )
}

export default Decision
