import { useEffect, useState } from 'react'

// Services
import { getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Components
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Methods
import { isMobile } from '../../../common/methods/devices'
import { getRecord } from '../../../common/methods/storage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { PROFFESIONS_GALAXIES } from '../../../constants/galaxies'

// Images
import InterestButtonMobile from '../../../assets/images/bg-interest-mobile.svg'
import InterestButtonSelectedMobile from '../../../assets/images/bg-interest-selected-mobile.svg'
import InterestButtonSelected from '../../../assets/images/bg-interest-selected.svg'
import InterestButton from '../../../assets/images/bg-interest.svg'

const Professions = () => {
  const record = getRecord()

  const [isloading, setLoading] = useState<boolean>(true)
  const [loadedImages, setLoadedImages] = useState<boolean>(false)
  const [features, setFeatures] = useState<any[]>([])
  const [selectedList, setSelectedList] = useState<any[]>([])

  useEffect(() => {
    getFeatures()
    getProfessions()

    setTimeout(() => setLoading(false), 1000)
  }, [])

  useEffect(() => {
    const preloadImages = async () => {
      try {
        const promises = PROFFESIONS_GALAXIES.map(galaxy => {
          return Promise.all([
            new Promise((resolve, reject) => {
              // eslint-disable-next-line no-undef
              const simpleImg = new Image()
              simpleImg.src = galaxy.simple
              simpleImg.onload = resolve
              simpleImg.onerror = reject
            }),
            new Promise((resolve, reject) => {
              // eslint-disable-next-line no-undef
              const selectedImg = new Image()
              selectedImg.src = galaxy.selected
              selectedImg.onload = resolve
              selectedImg.onerror = reject
            })
          ])
        })

        await Promise.all(promises)

        setLoadedImages(true)
      } catch (error) {
        console.error('Error al precargar imágenes', error)
      }
    }

    preloadImages()
  }, [])

  const getProfessions = () => {
    if (record) {
      let professions = record.features.filter((fet: any) => fet.type === 'professions' && fet.profiles.selected)

      professions = professions.map((profession: any) => ({ ...profession, feature: profession, weight: null, feature_id: profession.id, selected: profession.profiles.selected }))

      if (professions.length) setSelectedList([...professions])
    }
  }

  const onSetSelectedList = (data: any) => {
    const selected_list = selectedList
    const index = selected_list.findIndex(sl => sl.id === data.id)

    if (index === -1) selected_list.push({ ...data, feature: data, weight: null, selected: true, feature_id: data.id })
    else selected_list[index].selected = !selected_list[index].selected

    setSelectedList([...selected_list])
  }

  const getFeatures = async () => {
    await getAllFeaturesByType('professions')
      .then(data => setFeatures(data.data))
  }

  const isContainKey = (data: any) => selectedList.findIndex(sl => sl.id === data.id && sl.selected) !== -1

  const onGoToNextStep = async () => {
    await createProfilesByList(selectedList)
      .then(async (data) => { if (data.status) await updateRecord({ step: 6 }) })
  }

  return (

    <article className="bg-main bg-loading-page" id="home">
      <div className="w-screen h-screen quiz-screen-background relative bg-feature py-4">

        <ButtonRedirect action="return" linkReturn={PAGES.CONVERSATION_2} text="return"></ButtonRedirect>
        {
          selectedList.filter(sl => sl.selected).length > 0 &&
          <ButtonRedirect action="next" callback={onGoToNextStep} linkReturn={PAGES.TALENTS} text="next"></ButtonRedirect>
        }

        <BadgeStep currentStep={5}></BadgeStep>
        <TitleMessage
          index={0}
          useLoading={false}
          size="w-1/2 max-w-[800px] h-auto pl-[10%]"
          title="Selecciona tus galaxias profesionales"
          message={isMobile() ? 'Puedes seleccionar más de una opción pero piensa bien qué es lo que REALMENTE quieres.' : 'Puedes seleccionar más de una opción pero piensa bien<br />qué es lo que REALMENTE quieres.'} />

        {
            isloading && !loadedImages
              ? <></>
              : <div className="absolute xl:left-[13.3%] bottom-0 w-full xl:w-3/4 h-3/4 animation-charge max-[480px]:h-[calc(100%-85px)]" style={{ gridTemplateColumns: 'repeat(auto-fit, 240px)' }}>
              {
                features.map((feature: any, index) => {
                  const professionGalaxy = PROFFESIONS_GALAXIES.find(galaxy => galaxy.simple.includes(feature.id))
                  return (
                    <div key={feature.id} className={`w-full max-w-[168px] xl:max-w-[230px] flex flex-col items-center absolute cursor-pointer z-[450] transition-all ease-in duration-400 hover:scale-110 ${isContainKey(feature) ? '' : 'after:left-[40%] after:-bottom-[165%]'} ${index === 0 || index === 3 ? `bottom-[26%] max-[480px]:bottom-[32%] ${index === 0 ? 'left-[0%]' : 'right-[2%] max-[480px]:right-[0%]'}` : `${index === 1 ? 'bottom-[40%] left-[25%] max-[480px]:bottom-[60%] max-[480px]:left-[6%]' : 'bottom-[40%] right-[25%] max-[480px]:bottom-[60%] max-[480px]:right-[6%]'}`}`}
                        onClick={() => onSetSelectedList(feature)}>

                        <div className="w-full max-w-[200px] max-[480px]:w-[120px] max-[480px]:h-[48px] relative aspect-[3.8] bg-cover bg-no-repeat bg-center grid z-50 line-vertical-card"
                          style={{ backgroundImage: `url(${isContainKey(feature) ? (isMobile() ? InterestButtonSelectedMobile : InterestButtonSelected) : (isMobile() ? InterestButtonMobile : InterestButton)})` }}>
                            <div className="w-full self-center text-left pl-[27%] pr-[12%] ff-cg--bold text-[12px] text-white max-[480px]:h-full max-[480px]:flex max-[480px]:justify-center max-[480px]:items-center max-[480px]:pt-[15px] max-[480px]:pl-[15px] max-[480px]:pr-[15px] max-[480px]:pb-[5px]">
                              {feature.name}
                            </div>
                        </div>
                        <div className="w-[168px] h-[110px] max-[480px]:w-[112px] max-[480px]:h-[82px] xl:w-[250px] xl:h-[160px] bg-center bg-no-repeat bg-cover"
                          style={{ backgroundImage: `url(${isContainKey(feature) ? professionGalaxy?.selected : professionGalaxy?.simple})` }} />
                    </div>
                  )
                })
              }
            </div>
          }
      </div>
    </article>
  )
}

export default Professions
