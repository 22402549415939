import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

export interface IAuthContextProps {
  user: string;
  setUser?(...args: unknown[]): unknown;
  loadingAssets: boolean;
  setLoadingAssets(...args: unknown[]): unknown;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps)

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const initialUser = localStorage.getItem('tcm_auth_user') ?? ''

  const [user, setUser] = useState<string>(initialUser)
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false)

  useEffect(() => {
    localStorage.setItem('tcm_auth_user', user)
  }, [user])

  const value = useMemo(
    () => ({
      user,
      setUser,
      loadingAssets,
      setLoadingAssets
    }),
    [user]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext
