import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Methods
import { getShield } from '../../../common/methods/Shield'
import { getWorkspaceParams } from '../../../common/methods/params'
import { getChargeResult, getRecord, setRecord } from '../../../common/methods/storage'

// Components
import ConstelationChart from '../../../components/charts/ConstelationChart'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { STAR_CHART_URL } from '../../../constants/cards'
import { needs } from '../../../constants/lists/needs'
import { REACTIONS } from '../../../constants/reactions'

// Images
import constelationNoun from '../../../assets/images/constelations/constelation-noun.svg'
import starReverseWhite from '../../../assets/images/stars/star-card-reverse-white.svg'
import AppMessage from '../../../components/shared/AppMessage'
import { SHIELDS_URL } from '../../../constants/shields'

const Results = () => {
  const navigate = useNavigate()

  const { url } = getWorkspaceParams()
  const record = getRecord()

  const [shield, setShield] = useState<any>(null)

  const [tutorial, setTutorial] = useState<boolean>(getChargeResult())

  const [step, setStep] = useState<number>(getChargeResult() ? 0 : 1)
  const totalSteps = 1

  const getDataShield = () => {
    if (!record) return

    const shield_id = record.shield
    let _shield = null
    let _profiles = record.profiles.filter(({ percentage } : { percentage: number}) => percentage >= 25)
    if (_profiles.length >= 3) {
      _profiles = _profiles.sort((a: any, b: any) => b.percentage - a.percentage)
      const _profiles_for_search = _profiles.slice(0, 2).map((prf: any) => prf.name)
      _shield = SHIELDS_URL.find(shild => {
        const items = shild.items.map(it => it.title)
        let include = true
        for (const profile of _profiles_for_search) {
          if (!items.includes(profile)) {
            include = false
          }
        }

        return shild.items.length >= 3 && include
      })
    }
    const shieldData = getShield(shield_id)

    if (_shield) {
      shieldData.name = _shield.name
      shieldData.chart = _shield.chart
      shieldData.sizeChart = _shield.sizeChart
      shieldData.positionsHigh = _shield.positionsHigh
      shieldData.positionsLow = _shield.positionsLow
    }

    setShield(shieldData)
  }

  const getVerbs = () => {
    if (!record || !shield) return []

    const { features } = record

    const mostHighFeatures = features.filter((feature: any) => needs.includes(feature.type) && [3, 4].includes(parseInt(feature.profiles.weight)))

    let mainFeatures: any[] = []
    let secondayFeatures: any[] = []

    if (shield.type === 'SIMPLE') {
      mainFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id === shield.id)
      secondayFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id !== shield.id)
    } else {
      mainFeatures = features.filter((feature: any) => needs.includes(feature.type) && parseInt(feature.profiles.weight) === 4)
      secondayFeatures = features.filter((feature: any) => parseInt(feature.profiles.weight) === 3)
    }

    mainFeatures = mainFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsHigh[index]
    }))

    secondayFeatures = secondayFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsLow[index]
    })).slice(0, 6)

    const verbs = [...mainFeatures, ...secondayFeatures]

    return verbs
  }

  const getNouns = () => {
    if (!record) return []

    const { features } = record

    const reactionLoveNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LOVE)
    const reactionLikeNouns:any[] = features.filter((feature: any) => feature.type === 'nouns' && feature.parent_id && feature.profiles.reaction === REACTIONS.LIKE)

    let nouns = [...reactionLoveNouns, ...reactionLikeNouns]

    const positions: string[] = [
      'top-[220px] left-[206px]',
      'top-[220px] right-[206px]',
      'top-[285px] left-[136px]',
      'top-[285px] right-[136px]',
      'top-[336px] left-[254px]',
      'top-[186px] left-[165px]',
      'top-[186px] right-[165px]',
      'top-[150px] left-[125px]',
      'top-[150px] right-[125px]',
      'top-[125px] left-[252px]',
      'top-[85px] left-[196px]',
      'top-[85px] right-[196px]',
      'top-[54px] left-[253px]',
      '-top-[2px] left-[253px]',
      'top-[370px] left-[215px]',
      'top-[370px] right-[215px]',
      'top-[425px] left-[254px]',
      'top-[194px] left-[102px]',
      'top-[194px] right-[102px]',
      'top-[295px] left-[88px]',
      'top-[296px] right-[84px]',
      'top-[110px] left-[90px]',
      'top-[110px] right-[90px]',
      'top-[85px] left-[142px]',
      'top-[85px] right-[142px]',
      'top-[218px] left-[38px]',
      'top-[218px] right-[38px]',
      'top-[325px] left-[38px]',
      'top-[325px] right-[38px]',
      'top-[214px] -left-[40px]',
      'top-[214px] -right-[40px]'
    ]

    nouns = nouns.map((noun: any, index: number) => ({
      name: noun.name,
      star: STAR_CHART_URL.find(star => star.includes(noun.parent_id)),
      position: positions[index]
    }))

    return nouns
  }

  const onViewDetailVocationalConstelation = () => {
    setRecord({
      ...record,
      last_page: PAGES.RESULT.GENERAL
    })
    navigate(`/${url}${PAGES.RESULT.VOCATIONAL}`)
  }

  const onViewDetailNounsConstelation = () => {
    setRecord({
      ...record,
      last_page: PAGES.RESULT.GENERAL
    })
    navigate(`/${url}${PAGES.RESULT.NOUNS}`)
  }

  useEffect(() => {
    getDataShield()
  }, [])

  useEffect(() => {
    if (step === totalSteps) {
      setRecord({
        ...record,
        charge_result: false
      })
      setTutorial(false)
    }
  }, [step])

  return (
        <article className={`bg-dashboard loading-conversation ${tutorial ? 'bg-oldwoman' : ''}`} id="home">
            <section className={`w-screen h-screen quiz-screen-background relative flex flex-col py-4 ${tutorial ? 'cloud-page' : 'bg-compass '}`}>
                { tutorial && <div className="gradient-purple" /> }
                {
                    tutorial &&
                    <div className="fixed top-0 bottom-0 left-0 right-0 z-10 before:opacity-50 before:bg-[#0D0416] before:w-full before:h-full before:absolute max-[480px]:top-[14px]">
                        <AppMessage
                            index={0}
                            useAnimationLoading={false}
                            size="w-1/2 max-w-[800px] h-auto"
                            title='La vocación tiene verbos y sustantivos. Toma en cuenta ambos para una decisión consciente.'
                            message='' />
                    </div>
                }
                <ButtonRedirect
                    action="next"
                    text="next"
                    linkReturn={ step === totalSteps ? PAGES.RESULT.NOUNS : PAGES.RESULT.GENERAL}
                    callback={ step === totalSteps ? undefined : () => setStep(step + 1) }
                />
                <BadgeStep type="user" />
                <TitleMessage
                    index={0}
                    useLoading={false}
                    title="Explora tus constelaciones y mírate a tí mismo"
                    message=""
                    size={`w-1/2 max-w-[800px] h-auto pl-[13%] ${tutorial ? 'opacity-0 invisible' : ''}`}
                />
               {
                !tutorial &&
                <div className="w-full flex-1 flex flex-wrap xl:gap-x-5 items-center justify-center xl:py-2">
                  <div className="h-full max-[480px]:h-[calc(100%-60px)] w-1/2 flex flex-col gap-y-2 items-center justify-center flex-1 px-10 xl:py-4 max-[480px]:py-2">
                      {
                          shield &&
                          <>
                              <div className="w-[370px] h-[70px] max-[480px]:w-[150px] bg-center bg-no-repeat bg-full" style={{ backgroundImage: `url(${shield.card})` }}>
                                  <div className="w-full h-full max-[480px]:h-[70px] flex flex-col items-center justify-center pt-1 pb-4 px-5 max-[480px]:pt-[1px] max-[480px]:pb-2 max-[480px]:px-3 max-[480px]:text-center">
                                      <p className="ff-m--regular text-white text-xs max-[480px]:text-[.5rem] max-[480px]:leading-[.75rem]">Tu constelación vocacional</p>
                                      <div className="flex gap-x-1 items-center justify-center max-[480px]:text-[11px] max-[480px]:leading-[.8rem]">
                                          {
                                              shield.items.map((item: any, index: number) => {
                                                return (
                                                      <p key={item.color + item.title} className="ff-cp--bold text-xl max-[480px]:text-[11px] max-[480px]:leading-[.8rem]" style={{ color: item.color }}>{item.title}</p>
                                                )
                                              })
                                          }
                                      </div>
                                  </div>
                              </div>
                              <button onClick={onViewDetailVocationalConstelation} className={`flex items-center justify-center flex-1 transition-all ease-in-out duration-300 scale-75 max-[480px]:scale-[.22] max-[480px]:hover:scale-[.22] opacity-40 max-[480px]:opacity-100 hover:opacity-100 hover:scale-90 min-[1600px]:scale-[1] min-[1600px]:hover:scale-[1.15] min-[1800px]:scale-[1.15] min-[1800px]:hover:scale-[1.3] min-[2200px]:scale-[2] min-[2200px]:hover:scale-[2.15] cursor-pointer ${tutorial ? 'z-[10]' : 'z-[100]'}`}>
                                  <ConstelationChart size={shield.sizeChart} chart={shield.chart} features={getVerbs()} />
                              </button>
                          </>
                      }
                  </div>
                  <div className="h-full max-[480px]:h-[calc(100%-60px)] w-1/2 flex flex-col gap-y-2 items-center justify-center flex-1 px-10 xl:py-4 max-[480px]:py-2">
                      <div className="w-[370px] h-[70px] max-[480px]:w-[150px] bg-center bg-no-repeat bg-full" style={{ backgroundImage: `url(${starReverseWhite})` }}>
                          <div className="w-full h-full max-[480px]:h-[70px] flex flex-col items-center justify-center pt-1 pb-4 px-5 max-[480px]:pt-[1px] max-[480px]:pb-2 max-[480px]:px-3 max-[480px]:text-center">
                              <p className="ff-m--regular text-white text-xs max-[480px]:text-[.5rem] max-[480px]:leading-[.75rem]">Tu constelación de sustantivos</p>
                              <p className="ff-m--bold text-white text-xl max-[480px]:text-[11px] max-[480px]:leading-[.8rem]">Constelación de tus pasiones</p>
                          </div>
                      </div>
                      <button onClick={onViewDetailNounsConstelation} className={`flex items-center justify-center flex-1 transition-all ease-in-out duration-300 scale-75 max-[480px]:scale-[.25] max-[480px]:hover:scale-[.25] opacity-40 max-[480px]:opacity-100 hover:opacity-100 hover:scale-90 min-[1600px]:scale-[1] min-[1600px]:hover:scale-[1.15] min-[1800px]:scale-[1.15] min-[1800px]:hover:scale-[1.3] min-[2200px]:scale-[2] min-[2200px]:hover:scale-[2.15] cursor-pointer ${tutorial ? 'z-[10]' : 'z-[100]'}`}>
                          <ConstelationChart chart={constelationNoun} features={getNouns()} shadowText />
                      </button>
                  </div>
              </div>
               }
            </section>
        </article>
  )
}

export default Results
