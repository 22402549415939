import { useEffect, useState } from 'react'
import Tooltip from '../shared/Tooltip'

// Services
import { createReference, deleteReference } from '../../services/apis/career_reference'

// Constants
import { CAREER_CLASSIFICATION_COLOR, CAREER_CLASSIFICATION_LABEL } from '../../constants/classification'

// Images
import bgInstitution from '../../assets/images/cards/bg-institution.svg'
import iconCloseSimple from '../../assets/images/icons/icon-close-simple.svg'
import iconStarBigActive from '../../assets/images/icons/icon-star-active.svg'
import iconStarBig from '../../assets/images/icons/icon-star.svg'
import { isMobile } from '../../common/methods/devices'

const CardCareerDetail = ({ currentCareer }: { currentCareer: any }) => {
  const [career, setCareer] = useState<any>(null)
  const [classificationCareer, setClassificationCareer] = useState<any>(null)

  const [showMainVideo, setShowMainVideo] = useState<boolean>(false)
  const [isloading, setIsloading] = useState<boolean>(true)

  useEffect(() => {
    setCareer(currentCareer)
    getDataClassification()
  }, [currentCareer])

  const handleFavourite = async () => {
    if (career.career_reference_id != null) {
      await deleteReference(career.career_reference_id)
        .then(data => {
          if (data.status) {
            setCareer({
              ...career,
              career_reference_id: null
            })
          }
        })
    } else {
      await createReference(career.career_id)
        .then(data => {
          if (data.status) {
            setCareer({
              ...career,
              career_reference_id: data.data.id
            })
          }
        })
    }
  }

  const getDataClassification = () => {
    if (!currentCareer?.classification) return

    const { classification } = currentCareer

    const classificationType = classification as 'OPTIMA' | 'IDEAL' | 'DEFIANT' | 'AMBIGUOUS' | 'COMPLEMENT'
    const type = classificationType
    const label = CAREER_CLASSIFICATION_LABEL[classificationType]
    const color = CAREER_CLASSIFICATION_COLOR[classificationType]

    setClassificationCareer({ label, color, type })
  }

  const showPanel = () => {
    setShowMainVideo(true)
    setTimeout(() => {
      setIsloading(false)
    }, 200)
  }

  const hidePanel = () => {
    setIsloading(true)
    setTimeout(() => {
      setShowMainVideo(false)
    }, 600)
  }

  return (
        <>

            {
                showMainVideo &&
                <div className={`fixed top-[85px] right-0 left-0 z-20 grid place-content-center border-t-2 border-[#07DACB] delay-1000 overflow-hidden bg-[#070625]/[.9] w-full ${isloading ? 'h-0' : 'h-[calc(100%-85px)]'}`}>
                    <button onClick={hidePanel} className="absolute top-6 right-6 h-[40px] w-[40px] bg-no-repeat bg-cover bg-center cursor-pointer opacity-70 hover:opacity-100 hover:scale-110" style={{ backgroundImage: `url(${iconCloseSimple})` }}></button>
                    <iframe className="w-[750px] h-[calc(100dvh-300px)]" src={career.link} title={career.career}
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                </div>
            }
              <div className="col-[1_/_1] grid grid-rows-1 w-full h-full relative sidebar-border pr-[15px] max-[480px]:pr-0 max-[480px]:h-auto">
                      <div className="row-span-1 min-h-full aspect-[0.58] p-[15px] max-[480px]:py-0">
                       {
                          career &&
                          <div className="animation-charge flex flex-col py-[30px] pt-[40px] pb-[20px] xl:pb-[50px] px-[35px] justify-between h-full w-full bg-cover bg-no-repeat bg-top overflow-hidden xl:bg-container max-[480px]:h-[70px] max-[480px]:bg-[#070625] max-[480px]:rounded-[10px] max-[480px]:border-[#42324E] max-[480px]:border-2 max-[480px]:p-[2px]" style={{ backgroundImage: isMobile() ? 'none' : `url(${bgInstitution})` }}>
                          <div className="content-between max-[480px]:border-[#42324E]/[.7] max-[480px]:border-[1px] max-[480px]:rounded-[10px] max-[480px]:h-full max-[480px]:py-1 max-[480px]:px-1.5">
                              <div className="flex justify-between">
                                  {
                                      classificationCareer &&
                                      <span className='w-[81px] h-[14px] rounded-xl flex items-center text-center justify-center text-white ff-cp--bold text-[8px] mb-[11px] max-[480px]:text-[7px] max-[480px]:h-[12px] max-[480px]:mb-1'
                                          style={{ backgroundColor: classificationCareer?.color }}>
                                          { classificationCareer?.label }
                                      </span>
                                  }
                                  {
                                    isMobile()
                                      ? <button
                                          onClick={handleFavourite} className="w-[35px] h-[35px] xl:w-[48px] xl:h-[48px] bg-cover bg-no-repeat cursor-pointer bg-center max-[480px]:w-[22px] max-[480px]:h-[22px]"
                                          style={{ backgroundImage: `url(${career.career_reference_id != null ? iconStarBigActive : iconStarBig})` }}>
                                        </button>
                                      : <Tooltip
                                          text='Agregar a Carreras Seleccionadas' color="#fff" backgroundColor="#070625"
                                          direction="bottom"
                                          extraClass='h-[35px] xl:h-[48px] max-[480px]:h-[25px]'>
                                            <button onClick={handleFavourite}
                                              className="w-[35px] h-[35px] xl:w-[48px] xl:h-[48px] bg-cover bg-no-repeat cursor-pointer bg-center max-[480px]:w-[25px] max-[480px]:h-[25px]"
                                              style={{ backgroundImage: `url(${career.career_reference_id != null ? iconStarBigActive : iconStarBig})` }}>
                                            </button>
                                        </Tooltip>
                                  }
                              </div>
                              <div className="flex justify-start relative mb-[10px] gap-4 pr-[50px]">
                                  <div className="ff-cp--bold text-[18px] xl:text-[20px] leading-none max-[480px]:text-[14px]">
                                      <p className="text-white">{career?.career}</p>
                                  </div>
                              </div>
                            {
                              !isMobile() &&
                              <div className="min-h-[60px] max-h-[80px] xl:min-h-[100px] xl:max-h-[120px] text-[13px] leading-[16px] ff-cp--light text-white text-justify">
                                  { career?.description }
                              </div>
                            }
                          </div>
                          {
                            !isMobile() &&
                            <div className="flex items-center justify-center gap-[25px]">
                                {
                                    career?.link &&
                                    <div className="w-full h-[150px] xl:h-[190px] grid justify-center">
                                        <div className="w-full h-full relative bg-[#2D176C] rounded-[10px] overflow-hidden">
                                            <button className="absolute inset-0 cursor-pointer" onClick={showPanel}></button>
                                            <iframe className="w-full h-full" src={career.link} title={career.career}
                                                allowFullScreen
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                                            </iframe>
                                        </div>
                                    </div>
                                }
                            </div>
                          }
                      </div>
                       }
                    </div>
                </div>
        </>
  )
}

export default CardCareerDetail
