import { PAGES } from '../Pages'

import iconMonitor from '../../assets/images/icons/icon-monitor.svg'

export const loading = [
  {
    id: 'a4606e52-9213-11ed-a1eb-0242ac120002',
    title: '',
    content: 'Abre tu mente y tu corazón.',
    next: PAGES.CONVERSATION_1
  },
  {
    id: 'a460712c-9213-11ed-a1eb-0242ac120002',
    title: '',
    content: 'Estamos buscando carreras candidatas para tí.',
    next: '/results'
  },
  {
    id: '8eebc5cb-aead-4b23-8c43-1144fd152b10',
    title: '¡Gracias!',
    content: 'Te daremos tus resultados al finalizar el taller.'
    // "next": "/dashboard"
  },
  {
    id: 'z0656e78-9813-11fd-a4e0-0242ac120602',
    title: '',
    icon: iconMonitor,
    content: 'Por el momento, esta experiencia solo se puede realizar en una PC o Laptop.'
  }
]
