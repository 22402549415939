import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd'
import { produce } from 'immer'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Services
import { getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Components
import { getWorkspaceParams } from '../../../common/methods/params'
import DraggableCard from '../../../components/dnd/DraggableCard'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { NEED_NAMES, needs } from '../../../constants/lists/needs'
import { REACTIONS } from '../../../constants/reactions'

// Methods
import { getRecord } from '../../../common/methods/storage'

// Images
import bgPriorizationVerbsMobile from '../../../assets/images/backgrounds/bg-priorization-verbs-mobile.svg'
import bgPriorizationVerbs from '../../../assets/images/backgrounds/bg-priorization-verbs.svg'
import bgDropZone from '../../../assets/images/bg-drop-zone.svg'
import { isMobile } from '../../../common/methods/devices'

const Needs = () => {
  const { token } = useParams()
  const { url } = getWorkspaceParams()
  const navigate = useNavigate()

  const record = getRecord()

  // const [verbs, setVerbs] = useState<any[]>([])

  const [isloading, setLoading] = useState<boolean>(true)

  const [currentPage, setCurrentPange] = useState(15)

  const [next, setNext] = useState('')
  const [previous, setPrevious] = useState('')

  const [features, setFeatures] = useState<any[]>([])

  const [showTitle, setShowTitle] = useState<boolean>(true)
  const [title, setTitle] = useState<string>('')

  const [data, setData] = useState<any>({})
  const [unselectedFeatures, setUnselectedFeatures] = useState<any>({
    alternative: {
      id: 'alternative',
      title: '',
      featureIds: ['']
    }
  })

  const title_tierlist = ['S', 'A', 'B', 'C', 'Nada']

  const VERB_POSITIONS = [
    { position: 'top-[300px] left-[160px] max-[480px]:left-[auto] max-[480px]:right-[20px] max-[480px]:top-[30px]' },
    { position: 'top-[30px] left-[100px] max-[480px]:left-[20px] max-[480px]:top-[30px]' },
    { position: 'top-[120px] left-[160px] max-[480px]:left-[calc(100dvw*.28)] max-[480px]:top-0' },
    { position: 'top-[210px] left-[100px] max-[480px]:left-[auto] max-[480px]:right-[calc(100dvw*.28)] max-[480px]:top-0' }
  ]

  const DROP_ZONES_POSITIONS = [
    { position: 'right-[41.5%] top-[60%] max-[480px]:bottom-[auto] max-[480px]:top-[46%] max-[480px]:right-[auto] max-[480px]:left-[22%]' },
    { position: 'right-[61.5%] top-[55%] max-[480px]:bottom-[auto] max-[480px]:top-[33%] max-[480px]:left-[auto] max-[480px]:right-[20%]' },
    { position: 'right-[78.5%] top-[90%] max-[480px]:bottom-[auto] max-[480px]:top-[21%] max-[480px]:right-[auto] max-[480px]:left-[18%]' },
    { position: 'right-[97.2%] top-[40%] max-[480px]:bottom-[auto] max-[480px]:top-[10%] max-[480px]:right-[auto] max-[480px]:left-[49%]' }
  ]

  useEffect(() => {
    setTitle(NEED_NAMES.find(nd => nd.code === token)?.name ?? '')
    setShowTitle(false)
    setLoading(true)
    getFeatures()
    setSteps()
  }, [token])

  useEffect(() => {
    setShowTitle(true)
    setTimeout(() => {
      setLoading(false)
    }, 800)
  }, [features])

  const onDragEnd = (result: any) => {
    console.log('Drag Result:', result)

    if (result.reason === 'DROP') {
      const { destination, source, draggableId } = result
      if (!result.destination) {
        const unselectedColumnId = result.source.droppableId
        const start = unselectedFeatures[unselectedColumnId]
        const newFeatureIds = Array.from(start.featureIds)
        newFeatureIds.splice(result.source.index, 1)
        newFeatureIds.splice(result.source.index, 0, result.draggableId)

        const newState = produce(data, (draftState: any) => {
          if (draftState && draftState.unselectedFeatures && draftState.unselectedFeatures[unselectedColumnId]) {
            draftState.unselectedFeatures[unselectedColumnId].featureIds = newFeatureIds
          }
        })

        setData(newState)
        return
      }

      if (result.source.droppableId === result.destination.droppableId) {
        return
      }

      console.log('Source:', result.source)
      console.log('Destination:', destination)

      const start = unselectedFeatures[result.source.droppableId]
      const finish = data?.selectedFeatures[destination.droppableId]

      if (start === finish) {
        const newFeatureIds = Array.from(start.featureIds)
        newFeatureIds.splice(source.index, 1)
        newFeatureIds.splice(destination.index, 0, draggableId)

        const newColumn = { ...start, featureIds: newFeatureIds }

        console.log('start', start)
        console.log('newColumn', newColumn)

        const newState = {
          ...data,
          selectedFeatures: {
            ...data?.selectedFeatures,
            [newColumn.id]: newColumn
          },
          unselectedFeatures: {
            ...data.unselectedFeatures,
            [newColumn.id]: newColumn
          }
        }

        setData(newState)
        return
      }

      const startFeatureIds = Array.from(start.featureIds)
      startFeatureIds.splice(source.index, 1)
      const newStart = { ...start, featureIds: startFeatureIds }

      const finishFeatureIds = finish?.featureIds ? Array.from(finish.featureIds) : []
      finishFeatureIds.splice(destination.index, 0, draggableId)
      const newFinish = { ...finish, featureIds: finishFeatureIds }

      const newState = {
        ...data,
        selectedFeatures: {
          ...data?.selectedFeatures,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      }

      setUnselectedFeatures({
        ...unselectedFeatures,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      })

      setData(newState)
    }
  }

  const getVerbs = (verbList: any[]) => {
    if (record && verbList) {
      const verbIds = verbList.map(v => v.id)
      const verbs: any[] = record.features.filter((feature: any) => verbIds.includes(feature.id) && feature.profiles.reaction === 'LOVE')

      // setVerbs(verbs)

      let columns: any = {}
      const column_name: any[] = []

      verbs.forEach((verb: any, index: number) => {
        column_name.push(`column-${index + 1}`)
        columns = {
          ...columns,
          [`column-${index + 1}`]: {
            id: `column-${index + 1}`,
            title: title_tierlist[index],
            color: '#FF009E',
            weight: 4 - index,
            featureIds: verbs.filter((verb: any) => parseFloat(verb.profiles.weight) === (4 - index) && verb.profiles.selected && verb.profiles.reaction === 'LOVE').map((verb: any) => verb.id) ?? []
          }
        }
      })

      setUnselectedFeatures({
        alternative: {
          id: 'alternative',
          title: '',
          featureIds: verbs.filter((verb: any) => verb.profiles.selected).length > 0 ? verbList.filter((vrb: any) => !verbs.map((verb: any) => verb.id).includes(vrb.id) || verbs.findIndex((verb: any) => verb.id === vrb.id && verb.profiles.selected) === -1).map((verb: any) => verb.id) : verbIds
        },
        ...columns
      })
      setData({
        selectedFeatures: { ...columns },
        unselectedFeaturesOrder: ['alternative'],
        selectedFeaturesOrder: column_name
      })
    }
  }

  const isDraggable = (verbId: string) => {
    if (record) return record.features.findIndex((feature: any) => feature.id === verbId && feature.profiles.reaction === 'LOVE') !== -1
    return true
  }

  const getFeatures = async () => {
    await getAllFeaturesByType(token!, true)
      .then((data) => {
        if (data.data.length === 0) {
          navigate(`/${url}/verbs/${token}`);
        } else {
          setFeatures([...data.data]);
          getVerbs(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(`/${url}/verbs/${token}`);
      });
  };

  const setSteps = () => {
    const need_index = needs.findIndex(nd => nd === token!)
    setCurrentPange(15 + (need_index * 2))

    setPrevious(`/verbs/${token}`)

    if (need_index === 5) setNext(isMobile() ? PAGES.PROFILES : PAGES.DECISION_2)
    else {
      const next_need = needs[need_index + 1]
      setNext(`/verbs/${next_need}`)
    }
  }

  const featureById = (id: string) => features.find((feature: any) => feature.id === id)

  const getRecordVerbs = () => record?.features.filter((feature: any) => feature.type === token && feature.profiles.reaction === REACTIONS.LOVE).length ?? 0

  const getSelectedList = () => {
    let selectedList: any = [];

    [...Array(5)].forEach((value: any, index: number) => {
      const column_data = data?.selectedFeatures[`column-${index + 1}`]
      if (column_data?.featureIds?.length > 0) {
        const featureDatas = features.filter((fet: any) => column_data.featureIds.includes(fet.id))
        const record = getRecord()
        if (record) {
          const verbs = record.features.filter((pf: any) => pf.type === token && pf.profiles.reaction === 'LOVE')
          featureDatas.forEach((data: any) => {
            selectedList.push({
              ...data,
              feature: data,
              feature_id: data.id,
              selected: true,
              weight: column_data.weight,
              reaction: verbs.find((vb: any) => vb.id === data.id)?.profiles?.reaction ?? null
            })
          })
        }
      }
    })

    selectedList = selectedList.filter((sl:any) => sl !== undefined)

    return features.map((feature: any) => {
      const isInclude = selectedList.some((sl: any) => sl.feature_id === feature.id)
      if (!isInclude) {
        return {
          ...feature,
          feature,
          feature_id: feature.id,
          selected: null,
          weight: 0,
          reaction: null
        }
      } else return selectedList.find((sl: any) => sl.feature_id === feature.id)
    })
  }

  const onGoToNextStep = async () => {
    const selectedList = getSelectedList()
    await createProfilesByList(selectedList)
      .then(async (data) => {
        if (data.status) updateRecord({ step: currentPage + 1 })
      })
      .catch(err => console.log(err))
  }

  return (

    <article className="bg-main bg-loading-page" id="home">
      <div className="w-screen h-screen relative overflow-hidden quiz-screen-background py-4">
        <div className="animation-charge-short  absolute w-3/4 h-full right-0 bottom-0 max-[480px]:w-full max-[480px]:h-auto max-[480px]:aspect-square max-[480px]:bg-center bg-no-repeat bg-left-top xl:bg-left bg-cover" style={{ backgroundImage: `url(${isMobile() ? bgPriorizationVerbsMobile : bgPriorizationVerbs})` }} />
        <ButtonRedirect action="return" linkReturn={previous} text="return" />
        <BadgeStep currentStep={currentPage}></BadgeStep>
        {
          unselectedFeatures.alternative.featureIds.length === (4 - getRecordVerbs()) &&
          <ButtonRedirect action="next" linkReturn={next} callback={onGoToNextStep} text="next" style='simple' />
        }
        {
          showTitle &&
          <TitleMessage
            index={0}
            useLoading={false}
            size="w-1/2 max-w-[550px] h-auto pl-[10%] mt-[-10px]"
            title={title}
            message={'Coloca lo más importante para tí más cerca de tu eje'}
          />
        }
        <div className="absolute inset-0 h-[400px] m-auto max-[480px]:h-[calc(100dvh*.81)] max-[480px]:mb-0">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="w-full h-full content-background px-[82px] relative">
              {
                  !isloading &&
                    (
                      <div key="dragable-zone" className="absolute w-3/4 h-full right-0 z-30 place-content-center animation-charge max-[480px]:w-full max-[480px]:h-[calc(100%-100px)] max-[480px]:bottom-0">
                          {
                            data?.selectedFeaturesOrder?.map((columnId: any, index: number) => {
                              const selectedFeaturesContainer = data?.selectedFeatures[columnId]
                              const features = selectedFeaturesContainer.featureIds?.map(
                                (featureId: any) => featureById(featureId)
                              )
                              let isDropDisable = false
                              isDropDisable = selectedFeaturesContainer.id !== 'list' && features.length > 0
                              return (
                                  <>
                                  <div key={columnId} className={`absolute ${DROP_ZONES_POSITIONS[index].position}`}>
                                    <Droppable
                                      droppableId={selectedFeaturesContainer.id}
                                      isDropDisabled={isDropDisable}
                                      direction="vertical">
                                      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                        <div
                                          className="w-[48px] h-[48px] flex items-baseline justify-center bg-no-repeat bg-center bg-cover"
                                          style={{ backgroundImage: `url(${bgDropZone})` }}
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}>
                                          {
                                            features.map((feature: any, index: number) => (
                                              <DraggableCard key={feature.id} feature={feature} index={index} disabled={false} />
                                            ))
                                          }
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                  </>
                              )
                            })
                          }

                        </div>
                    )
                }
                {
                    !isloading &&
                    data?.unselectedFeaturesOrder?.map((columnId: any) => {
                      const unselectedFeaturesContainer = unselectedFeatures[columnId]
                      const feature_list = unselectedFeaturesContainer.featureIds?.map((featureId: any) => featureById(featureId))
                      return (
                        <Droppable
                          key={columnId}
                          droppableId={unselectedFeaturesContainer.id}
                          direction="vertical">
                          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                            <>
                              <div ref={provided.innerRef} {...provided.droppableProps} className="absolute w-1/4 h-full left-0 top-0 bottom-0 max-[480px]:w-full max-[480px]:h-[100px]">
                                <div className="w-full h-full relative">
                                {
                                  feature_list.map((feature: any, index: number) => {
                                    return (
                                      <div key={feature.id} className={`absolute ${VERB_POSITIONS[feature.order - 1].position} ${isDraggable(feature?.id) ? '' : 'opacity-50'}`}>
                                        <DraggableCard key={feature.id} feature={feature} index={index} disabled={!isDraggable(feature?.id)} />
                                      </div>
                                    )
                                  })
                                }
                                </div>
                              </div>
                              {provided.placeholder}
                            </>
                          )}
                        </Droppable>
                      )
                    })
                  }
              </div>
            </DragDropContext>
          </div>

      </div>
    </article>
  )
}

export default Needs
